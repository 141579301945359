<template>
  <div>
    <a-form :label-col="{}" :wrapper-col="{}" class="clientdata">
      <a-row :gutter="48">
        <a-col
          v-for="v in $v.fields.$each.$iter"
          :key="v.$model.id"
          class="form-item"
          :span="12"
        >
          <a-form-item
            :has-feedback="v.value.minLength"
            :validate-status="v.value.$error ? 'error' : ''"
            :label="v.$model.name"
            :help="v.value.$error && 'Необходимо заполнить поле'"
          >
            <a-input
              v-model.trim="v.value.$model"
              @input="
                e =>
                  setField({
                    name: v.$model.createclientname,
                    value: e.target.value,
                  })
              "
              :placeholder="v.$model.placeholder"
              :value="checkFields(v.$model.createclientname)"
            />
          </a-form-item>
        </a-col>
      </a-row>
    </a-form>
    <div
      v-if="this.$route.name !== 'CreateNewDealer' && !isOwner"
      class="buttonsholder"
    >
      <ButtonsHolderUpdateDealer />
    </div>
  </div>
</template>

<script>
import { mapMutations, mapGetters } from "vuex"
import { required } from "vuelidate/lib/validators"

import ButtonsHolderUpdateDealer from "@/components/dealers/ButtonsHolderUpdateDealer"

export default {
  name: "DealerData",
  components: {
    ButtonsHolderUpdateDealer,
  },
  computed: mapGetters({
    createForm: "dealers/createForm",
    isOwner: "auth/isOwner",
  }),
  data: () => ({
    fields: [
      {
        id: 1,
        name: "Наименование пользователя",
        placeholder: "Введите имя пользователя",
        createclientname: "name",
        value: "",
      },
      {
        id: 2,
        name: "Расчетный счет",
        placeholder: "Введите № расчетного счета",
        createclientname: "legalInfoPaymentAccount",
        value: "",
      },
      {
        id: 3,
        name: "Наименование юридического лица",
        placeholder: "Введите полное название юр. лица",
        createclientname: "legalInfoName",
        value: "",
      },
      {
        id: 4,
        name: "БИК банка",
        placeholder: "БИК банка",
        createclientname: "legalInfoBankId",
        value: "",
      },
      {
        id: 5,
        name: "ИНН",
        placeholder: "Введите ИНН",
        createclientname: "legalInfoInn",
        value: "",
      },
      {
        id: 6,
        name: "Наименование банка",
        placeholder: "Наименование банка",
        createclientname: "legalInfoBankName",
        value: "",
      },
      {
        id: 7,
        name: "КПП",
        placeholder: "КПП",
        createclientname: "legalInfoKpp",
        value: "",
      },
      {
        id: 8,
        name: "Корр. счет",
        placeholder: "Корр. счет",
        createclientname: "legalInfoCorrespondedAccount",
        value: "",
      },
      {
        id: 9,
        name: "ОГРН",
        placeholder: "Введите ОГРН",
        createclientname: "legalInfoOgrn",
        value: "",
      },
      {
        id: 10,
        name: "Должность руководителя",
        placeholder: "Должность руководителя",
        createclientname: "headPosition",
        value: "",
      },
      {
        id: 11,
        name: "ОКПО",
        placeholder: "Введите ОКПО",
        createclientname: "legalInfoOkpo",
        value: "",
      },
      {
        id: 12,
        name: "ФИО руководителя",
        placeholder: "ФИО руководителя",
        createclientname: "headName",
        value: "",
      },
      {
        id: 13,
        name: "Юридический адрес",
        placeholder: "Юридический адрес",
        createclientname: "legalInfoAddress",
        value: "",
      },
      {
        id: 14,
        name: "Документ-основание",
        placeholder: "Введите навзание и № документа",
        createclientname: "legalInfoUnderlyingDocument",
        value: "",
      },
      {
        id: 15,
        name: "Фактический адрес",
        placeholder: "Фактический адрес",
        createclientname: "legalInfoPhysicalAddress",
        value: "",
      },
      {
        id: 16,
        name: "Телефон",
        placeholder: "Телефон",
        createclientname: "legalInfoPhone",
        value: "",
      },
      {
        id: 17,
        name: "Сайт",
        placeholder: "Сайт",
        createclientname: "legalInfoSite",
        value: "",
      },
    ],
  }),
  validations: {
    fields: {
      required,
      $each: {
        value: {
          required,
        },
      },
    },
  },
  watch: {
    createForm() {
      this.setFormFields()
    },
  },
  mounted() {
    if (this.$route.name === "CreateNewDealer") {
      this.setClearFields()
    } else {
      this.setFormFields()
    }
  },
  methods: {
    checkDealer() {
      this.$v.fields.$touch()
    },
    ...mapMutations({
      setField: "dealers/SET_CREATE_FORM_FIELD",
    }),

    setClearFields() {
      this.fields = this.fields.map(item => {
        item.value = ""
        return item
      })
    },
    setFormFields() {
      this.fields = this.fields.map(item => {
        const createFormValue = this.createForm[item.createclientname]
        if (createFormValue) {
          item.value = createFormValue
        }
        return item
      })
    },
    checkFields(e) {
      // TODO: Переделать
      if (e === "name") {
        return this.createForm.name
      } else if (e === "legalInfoPaymentAccount") {
        return this.createForm.legalInfoPaymentAccount
      } else if (e === "legalInfoName") {
        return this.createForm.legalInfoName
      } else if (e === "legalInfoBankId") {
        return this.createForm.legalInfoBankId
      } else if (e === "legalInfoInn") {
        return this.createForm.legalInfoInn
      } else if (e === "legalInfoBankName") {
        return this.createForm.legalInfoBankName
      } else if (e === "legalInfoKpp") {
        return this.createForm.legalInfoKpp
      } else if (e === "legalInfoCorrespondedAccount") {
        return this.createForm.legalInfoCorrespondedAccount
      } else if (e === "legalInfoOgrn") {
        return this.createForm.legalInfoOgrn
      } else if (e === "headPosition") {
        return this.createForm.headPosition
      } else if (e === "legalInfoOkpo") {
        return this.createForm.legalInfoOkpo
      } else if (e === "headName") {
        return this.createForm.headName
      } else if (e === "legalInfoAddress") {
        return this.createForm.legalInfoAddress
      } else if (e === "legalInfoUnderlyingDocument") {
        return this.createForm.legalInfoUnderlyingDocument
      } else if (e === "legalInfoPhysicalAddress") {
        return this.createForm.legalInfoPhysicalAddress
      } else if (e === "legalInfoPhone") {
        return this.createForm.legalInfoPhone
      } else if (e === "legalInfoSite") {
        return this.createForm.legalInfoSite
      }
    },
    handleAcrtionDealerData() {
      this.checkDealer()
      if (this.$v.$error) {
        window.scrollTo(0, "100%")
      }
    },
  },
}
</script>

<style lang="scss">
.buttonsholder {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: left;
  padding: 0 auto;
}
.clientdata {
  label {
    font-size: 13px !important;
    color: rgba(black, 0.7);
  }
}
</style>
