<template>
  <div class="flex">
    <a-button type="primary" class="clientinfo-button" @click="write">
      Сохранить
    </a-button>
  </div>
</template>

<script>
import { mapActions } from "vuex"
export default {
  name: "ButtonsHolderUpdateDealer",
  computed: {},
  methods: {
    ...mapActions({
      updateDealer: "dealers/updateDealer",
    }),
    write() {
      this.updateDealer()
    },
  },
}
</script>

<style scoped>
.flex {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}
.clientinfo-button {
  margin: 20px 0 10px;
}
</style>
